import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'

library.add(faEye, faEyeSlash)

class Util {
  static install (vue) {
    const baseUrl = 'https://stats3cx.snsolutions.fr'
    function generateFormData (data) {
      const bodyFormData = new FormData()
      try {
        Object.keys(data).forEach(key => {
          if (typeof data[key] === 'string' || typeof data[key] === 'number') {
            bodyFormData.set(key, data[key])
          } else if (typeof data[key] === 'object' && data[key] !== null) {
            try {
              data[key].forEach(elem => {
                bodyFormData.append('key', elem)
              })
            } catch (e) {
              console.error('data: ' + JSON.stringify(data), 'key: ' + key, 'data[key]: ' + data[key], 'error: ' + e)
            }
          }
        })
      } catch (e) {
        console.error('data: ' + JSON.stringify(data), 'error: ' + e)
      }
      return bodyFormData
    }

    if (vue.config?.globalProperties && !vue.config.globalProperties.$genFD) {
      // vue 3
      vue.config.globalProperties.$genFD = generateFormData
      vue.provide('$genFD', generateFormData)
    } else if (!Object.prototype.hasOwnProperty.call(vue, '$genFD')) {
      // vue 2

      // eslint-disable-next-line
      vue.prototype.$genFD = generateFormData
      vue.genFD = generateFormData
    }

    if (vue.config?.globalProperties && !vue.config.globalProperties.$baseUrl) {
      // vue 3
      vue.config.globalProperties.$baseUrl = baseUrl
      vue.provide('$baseUrl', baseUrl)
    } else if (!Object.prototype.hasOwnProperty.call(vue, '$baseUrl')) {
      // vue 2

      // eslint-disable-next-line
      vue.prototype.$baseUrl = baseUrl
      vue.baseUrl = baseUrl
    }
  }
}
import CircleElem from '@/components/CircleElem.vue'
import vueDebounce from 'vue-debounce'


createApp(App).component('font-awesome-icon', FontAwesomeIcon).component('Circle', CircleElem).use(router).use(Util).directive('debounce', vueDebounce({ lock: true })).mount('#app')
