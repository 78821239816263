<template>
  <span class="circle" :style="style">
  </span>
</template>

<script>
// import store from '@/store'
export default {
  name: 'CircleElem',
  props: {
    style: {
      default: () => {
        return {
          backgroundColor: 'green',
          width: '50px',
          height: '50px'
        }
      },
      type: Object
    }
  },
  watch: {

  },
  data () {
    return {

      // dataStore: store
    }
  },
  methods: {

  },
  mounted () {

  },
  created () {

  }
}
</script>

<style scoped>
.circle {
  border-radius: 50%;
  aspect-ratio: 1;
  display: inline-block;
  min-width: 10px;
  min-height: 10px;
}
</style>
