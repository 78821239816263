import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/dashboard',
    component: () => import('../dashboard/DashboardView.vue')
  },
  {
    path: '/callsManager',
    component: () => import('../calls/CallsManager.vue')
  },
  {
    path: '/agentManager',
    component: () => import('../agents/AgentsManager.vue')
  },
  {
    path: '/timeManager',
    component: () => import('../time/TimeManager.vue')
  },
  {
    path: '/users',
    component: () => import('../users/UsersManager.vue')
  },
  {
    path: '/support',
    component: () => import('../views/SupportPage.vue')
  },
  {
    path: '/dc',
    component: () => import('../views/DisconnectPage.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
  console.log(from, to)
  // check auth
  if(to.path == "/") {
    return '/dashboard'
  }
})

export default router
