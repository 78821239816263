<template>
  <img :src="dataStore.logoUrl"/>
</template>

<script>
import store from '@/store'
export default {
  name: 'LogoComponent',
  data () {
    return {
      dataStore: store
    }
  }
}
</script>

<style scoped>
img {
  width: 100%;
}
</style>
