const snsConfig = {
  baseUrl: 'https://stats3cx.snsolutions.fr',
  siteUrl: 'https://snsolutions.fr',
  web3cxUrl: 'https://snsolutions.3cx.fr/#/login',
  logoUrl: 'https://www.snsolutions.fr/wp-content/uploads/2024/02/cropped-logo-sns-20-bis.png'
}

let store = snsConfig // change this

let defaultConfig = {
  api: {
    apiUser: null,
    apiPassword: null
  }
}
store = {...store,...defaultConfig}

export default store