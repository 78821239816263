<template>
  <main class="sideBar">
    <div class="logo-container">
      <Logo/>
    </div>
    <div class="filterSelection">
      <VueDatePicker @closed="this.sendFilters()" v-model="date" range multi-calendars locale="fr-FR" :enable-time-picker="false" :format="formatDate" position="right" auto-apply required :teleport="true" :preset-dates="presetDates" class="mb-3">
        <template #preset-date-range-button="{ label, value, presetDate }">
          <span 
              role="button"
              :tabindex="0"
              @click="presetDate(value)"
              @keyup.enter.prevent="presetDate(value)"
              @keyup.space.prevent="presetDate(value)">
            {{ label }}
          </span>
        </template>
      </VueDatePicker>
      <div class="filter-container mb-3">
        <input name="sda" role="combobox" list="" class="form-control" @focus="sdaInputFocused = true; selectedSda = ''" @blur="handleBlur" v-model="selectedSda" placeholder="Sélectionnez une SDA" autocomplete="off" type="search"/>
        <datalist class="sideBar" id="sda" role="listbox" :style="{display: sdaInputFocused ? 'block' : 'none'}">
          <option v-for="(sda, index) in filteredSdaList" :key="index" @click="selectSda(sda.calledLine)">{{ sda.calledLine }}</option>
        </datalist>
      </div>
      <div class="filter-container mb-3">
        <input name="callGroup" role="combobox" list="" class="form-control" @focus="callGroupInputFocused = true; selectedCallGroup = ''" @blur="handleBlur" v-model="selectedCallGroup" placeholder="Sélectionnez un Groupe d'appel" autocomplete="off" type="search"/>
        <datalist class="sideBar" id="callGroup" role="listbox" :style="{display: callGroupInputFocused ? 'block' : 'none'}">
          <option v-for="(callGroup, index) in filteredCallGroupList" :key="index" @click="selectCallGroup(callGroup.queue)">{{ callGroup.queue }}</option>
        </datalist>
      </div>
      <div class="filter-container mb-3">
        <input name="agent" role="combobox" list="" class="form-control" @focus="agentsInputFocused = true; selectedAgent = ''" @blur="handleBlur" v-model="selectedAgent" placeholder="Sélectionnez un Agent" autocomplete="off" type="search"/>
        <datalist class="sideBar" id="agent" role="listbox" :style="{display: agentsInputFocused ? 'block' : 'none'}">
          <option v-for="(agent, index) in filteredAgentsList" :key="index" @click="selectAgent(agent.contactName)">{{ agent.contactName }}</option>
        </datalist>
      </div>
    </div>
    <div class="navigation-container">
      <h6>Navigation</h6>
      <router-link to="/dashboard" class="link">
        <svg viewBox="64 64 896 896" focusable="false" data-icon="dashboard" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M924.8 385.6a446.7 446.7 0 00-96-142.4 446.7 446.7 0 00-142.4-96C631.1 123.8 572.5 112 512 112s-119.1 11.8-174.4 35.2a446.7 446.7 0 00-142.4 96 446.7 446.7 0 00-96 142.4C75.8 440.9 64 499.5 64 560c0 132.7 58.3 257.7 159.9 343.1l1.7 1.4c5.8 4.8 13.1 7.5 20.6 7.5h531.7c7.5 0 14.8-2.7 20.6-7.5l1.7-1.4C901.7 817.7 960 692.7 960 560c0-60.5-11.9-119.1-35.2-174.4zM761.4 836H262.6A371.12 371.12 0 01140 560c0-99.4 38.7-192.8 109-263 70.3-70.3 163.7-109 263-109 99.4 0 192.8 38.7 263 109 70.3 70.3 109 163.7 109 263 0 105.6-44.5 205.5-122.6 276zM623.5 421.5a8.03 8.03 0 00-11.3 0L527.7 506c-18.7-5-39.4-.2-54.1 14.5a55.95 55.95 0 000 79.2 55.95 55.95 0 0079.2 0 55.87 55.87 0 0014.5-54.1l84.5-84.5c3.1-3.1 3.1-8.2 0-11.3l-28.3-28.3zM490 320h44c4.4 0 8-3.6 8-8v-80c0-4.4-3.6-8-8-8h-44c-4.4 0-8 3.6-8 8v80c0 4.4 3.6 8 8 8zm260 218v44c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8v-44c0-4.4-3.6-8-8-8h-80c-4.4 0-8 3.6-8 8zm12.7-197.2l-31.1-31.1a8.03 8.03 0 00-11.3 0l-56.6 56.6a8.03 8.03 0 000 11.3l31.1 31.1c3.1 3.1 8.2 3.1 11.3 0l56.6-56.6c3.1-3.1 3.1-8.2 0-11.3zm-458.6-31.1a8.03 8.03 0 00-11.3 0l-31.1 31.1a8.03 8.03 0 000 11.3l56.6 56.6c3.1 3.1 8.2 3.1 11.3 0l31.1-31.1c3.1-3.1 3.1-8.2 0-11.3l-56.6-56.6zM262 530h-80c-4.4 0-8 3.6-8 8v44c0 4.4 3.6 8 8 8h80c4.4 0 8-3.6 8-8v-44c0-4.4-3.6-8-8-8z"></path></svg>
        Tableau de bord
      </router-link>
      <router-link to="/callsManager" class="link">
        <svg viewBox="64 64 896 896" focusable="false" data-icon="phone" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M877.1 238.7L770.6 132.3c-13-13-30.4-20.3-48.8-20.3s-35.8 7.2-48.8 20.3L558.3 246.8c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l89.6 89.7a405.46 405.46 0 01-86.4 127.3c-36.7 36.9-79.6 66-127.2 86.6l-89.6-89.7c-13-13-30.4-20.3-48.8-20.3a68.2 68.2 0 00-48.8 20.3L132.3 673c-13 13-20.3 30.5-20.3 48.9 0 18.5 7.2 35.8 20.3 48.9l106.4 106.4c22.2 22.2 52.8 34.9 84.2 34.9 6.5 0 12.8-.5 19.2-1.6 132.4-21.8 263.8-92.3 369.9-198.3C818 606 888.4 474.6 910.4 342.1c6.3-37.6-6.3-76.3-33.3-103.4zm-37.6 91.5c-19.5 117.9-82.9 235.5-178.4 331s-213 158.9-330.9 178.4c-14.8 2.5-30-2.5-40.8-13.2L184.9 721.9 295.7 611l119.8 120 .9.9 21.6-8a481.29 481.29 0 00285.7-285.8l8-21.6-120.8-120.7 110.8-110.9 104.5 104.5c10.8 10.8 15.8 26 13.3 40.8z"></path></svg>
        Gestionnaire d'appels
      </router-link>
      <router-link to="/agentManager" class="link" v-if="!limitedSda">
        <svg viewBox="64 64 896 896" focusable="false" data-icon="usergroup-add" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M892 772h-80v-80c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v80h-80c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h80v80c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-80h80c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM373.5 498.4c-.9-8.7-1.4-17.5-1.4-26.4 0-15.9 1.5-31.4 4.3-46.5.7-3.6-1.2-7.3-4.5-8.8-13.6-6.1-26.1-14.5-36.9-25.1a127.54 127.54 0 01-38.7-95.4c.9-32.1 13.8-62.6 36.3-85.6 24.7-25.3 57.9-39.1 93.2-38.7 31.9.3 62.7 12.6 86 34.4 7.9 7.4 14.7 15.6 20.4 24.4 2 3.1 5.9 4.4 9.3 3.2 17.6-6.1 36.2-10.4 55.3-12.4 5.6-.6 8.8-6.6 6.3-11.6-32.5-64.3-98.9-108.7-175.7-109.9-110.8-1.7-203.2 89.2-203.2 200 0 62.8 28.9 118.8 74.2 155.5-31.8 14.7-61.1 35-86.5 60.4-54.8 54.7-85.8 126.9-87.8 204a8 8 0 008 8.2h56.1c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5 29.4-29.4 65.4-49.8 104.7-59.7 3.8-1.1 6.4-4.8 5.9-8.8zM824 472c0-109.4-87.9-198.3-196.9-200C516.3 270.3 424 361.2 424 472c0 62.8 29 118.8 74.2 155.5a300.95 300.95 0 00-86.4 60.4C357 742.6 326 814.8 324 891.8a8 8 0 008 8.2h56c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5C505.8 695.7 563 672 624 672c110.4 0 200-89.5 200-200zm-109.5 90.5C690.3 586.7 658.2 600 624 600s-66.3-13.3-90.5-37.5a127.26 127.26 0 01-37.5-91.8c.3-32.8 13.4-64.5 36.3-88 24-24.6 56.1-38.3 90.4-38.7 33.9-.3 66.8 12.9 91 36.6 24.8 24.3 38.4 56.8 38.4 91.4-.1 34.2-13.4 66.3-37.6 90.5z"></path></svg>
        Gestionnaire d'agents
      </router-link>
      <router-link to="/timeManager" class="link" v-if="!limitedSda">
        <svg viewBox="64 64 896 896" focusable="false" data-icon="usergroup-add" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M892 772h-80v-80c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v80h-80c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h80v80c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-80h80c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM373.5 498.4c-.9-8.7-1.4-17.5-1.4-26.4 0-15.9 1.5-31.4 4.3-46.5.7-3.6-1.2-7.3-4.5-8.8-13.6-6.1-26.1-14.5-36.9-25.1a127.54 127.54 0 01-38.7-95.4c.9-32.1 13.8-62.6 36.3-85.6 24.7-25.3 57.9-39.1 93.2-38.7 31.9.3 62.7 12.6 86 34.4 7.9 7.4 14.7 15.6 20.4 24.4 2 3.1 5.9 4.4 9.3 3.2 17.6-6.1 36.2-10.4 55.3-12.4 5.6-.6 8.8-6.6 6.3-11.6-32.5-64.3-98.9-108.7-175.7-109.9-110.8-1.7-203.2 89.2-203.2 200 0 62.8 28.9 118.8 74.2 155.5-31.8 14.7-61.1 35-86.5 60.4-54.8 54.7-85.8 126.9-87.8 204a8 8 0 008 8.2h56.1c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5 29.4-29.4 65.4-49.8 104.7-59.7 3.8-1.1 6.4-4.8 5.9-8.8zM824 472c0-109.4-87.9-198.3-196.9-200C516.3 270.3 424 361.2 424 472c0 62.8 29 118.8 74.2 155.5a300.95 300.95 0 00-86.4 60.4C357 742.6 326 814.8 324 891.8a8 8 0 008 8.2h56c4.3 0 7.9-3.4 8-7.7 1.9-58 25.4-112.3 66.7-153.5C505.8 695.7 563 672 624 672c110.4 0 200-89.5 200-200zm-109.5 90.5C690.3 586.7 658.2 600 624 600s-66.3-13.3-90.5-37.5a127.26 127.26 0 01-37.5-91.8c.3-32.8 13.4-64.5 36.3-88 24-24.6 56.1-38.3 90.4-38.7 33.9-.3 66.8 12.9 91 36.6 24.8 24.3 38.4 56.8 38.4 91.4-.1 34.2-13.4 66.3-37.6 90.5z"></path></svg>
        Gestion du temps
      </router-link>
    </div>
    <div class="navigation-container" v-if="!limitedSda">
      <h6>Paramètres</h6>
      <router-link to="/users" class="link">
        <svg viewBox="64 64 896 896" focusable="false" data-icon="chrome" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M928 512.3v-.3c0-229.8-186.2-416-416-416S96 282.2 96 512v.4c0 229.8 186.2 416 416 416s416-186.2 416-416v-.3.2zm-6.7-74.6l.6 3.3-.6-3.3zM676.7 638.2c53.5-82.2 52.5-189.4-11.1-263.7l162.4-8.4c20.5 44.4 32 93.8 32 145.9 0 185.2-144.6 336.6-327.1 347.4l143.8-221.2zM512 652.3c-77.5 0-140.2-62.7-140.2-140.2 0-77.7 62.7-140.2 140.2-140.2S652.2 434.5 652.2 512 589.5 652.3 512 652.3zm369.2-331.7l-3-5.7 3 5.7zM512 164c121.3 0 228.2 62.1 290.4 156.2l-263.6-13.9c-97.5-5.7-190.2 49.2-222.3 141.1L227.8 311c63.1-88.9 166.9-147 284.2-147zM102.5 585.8c26 145 127.1 264 261.6 315.1C229.6 850 128.5 731 102.5 585.8zM164 512c0-55.9 13.2-108.7 36.6-155.5l119.7 235.4c44.1 86.7 137.4 139.7 234 121.6l-74 145.1C302.9 842.5 164 693.5 164 512zm324.7 415.4c4 .2 8 .4 12 .5-4-.2-8-.3-12-.5z"></path></svg>
        Gestion des utilisateurs
      </router-link>
      <!-- <router-link to="/support" class="link">
        <svg viewBox="64 64 896 896" focusable="false" data-icon="question-circle" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path><path d="M623.6 316.7C593.6 290.4 554 276 512 276s-81.6 14.5-111.6 40.7C369.2 344 352 380.7 352 420v7.6c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V420c0-44.1 43.1-80 96-80s96 35.9 96 80c0 31.1-22 59.6-56.1 72.7-21.2 8.1-39.2 22.3-52.1 40.9-13.1 19-19.9 41.8-19.9 64.9V620c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8v-22.7a48.3 48.3 0 0130.9-44.8c59-22.7 97.1-74.7 97.1-132.5.1-39.3-17.1-76-48.3-103.3zM472 732a40 40 0 1080 0 40 40 0 10-80 0z"></path></svg>
        Support
      </router-link> -->
    </div>
    <div class="navigation-container">
      <h6>Utilitaire</h6>
      <router-link to="/dc" class="link">
        <svg viewBox="64 64 896 896" focusable="false" data-icon="logout" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M868 732h-70.3c-4.8 0-9.3 2.1-12.3 5.8-7 8.5-14.5 16.7-22.4 24.5a353.84 353.84 0 01-112.7 75.9A352.8 352.8 0 01512.4 866c-47.9 0-94.3-9.4-137.9-27.8a353.84 353.84 0 01-112.7-75.9 353.28 353.28 0 01-76-112.5C167.3 606.2 158 559.9 158 512s9.4-94.2 27.8-137.8c17.8-42.1 43.4-80 76-112.5s70.5-58.1 112.7-75.9c43.6-18.4 90-27.8 137.9-27.8 47.9 0 94.3 9.3 137.9 27.8 42.2 17.8 80.1 43.4 112.7 75.9 7.9 7.9 15.3 16.1 22.4 24.5 3 3.7 7.6 5.8 12.3 5.8H868c6.3 0 10.2-7 6.7-12.3C798 160.5 663.8 81.6 511.3 82 271.7 82.6 79.6 277.1 82 516.4 84.4 751.9 276.2 942 512.4 942c152.1 0 285.7-78.8 362.3-197.7 3.4-5.3-.4-12.3-6.7-12.3zm88.9-226.3L815 393.7c-5.3-4.2-13-.4-13 6.3v76H488c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h314v76c0 6.7 7.8 10.5 13 6.3l141.9-112a8 8 0 000-12.6z"></path></svg>
        Déconnexion
      </router-link>
    </div>
  </main>
</template>

<script>
import store from '@/store'
import Logo from '@/components/Logo.vue'
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  name: 'SideBar',
  components: {
    Logo,
    VueDatePicker
  },
  props: {

  },
  watch: {
    selectedSda (newVal) {
      // change Sda list
      if(newVal != '') {
        this.filteredSdaList = this.sdaList.filter(elem => elem.calledLine.toLowerCase().includes(newVal.toLowerCase()))
      }
      else {
        this.filteredSdaList = this.sdaList
        this.sendFilters()
      }
    },
    selectedCallGroup (newVal) {
      // change queue list
      if(newVal != '') {
        this.filteredCallGroupList = this.callGroupList.filter(elem => elem.queue.toLowerCase().includes(newVal.toLowerCase()))
      }
      else {
        this.filteredCallGroupList = this.callGroupList
        this.sendFilters()
      }
    },
    selectedAgent (newVal) {
      // change agent list
      if(newVal != '') {
        this.filteredAgentsList = this.agentsList.filter(elem => elem.contactName.toLowerCase().includes(newVal.toLowerCase()))
      }
      else {
        this.filteredAgentsList = this.agentsList
        this.sendFilters()
      }
    },
    optionIsClicked () {
      setTimeout(() => {
        this.optionIsClicked = false;
      }, 500)
    }
  },
  data () {
    return {
      date: [
        new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000),
        new Date()
      ],
      // ------------------------------
      sdaInputFocused: false,
      filteredSdaList: [],
      sdaList: [],
      selectedSda: '',
      // ------------------------------
      callGroupInputFocused: false,
      filteredCallGroupList: [],
      callGroupList: [],
      selectedCallGroup: '',
      // ------------------------------
      agentsInputFocused: false,
      filteredAgentsList: [],
      agentsList: [],
      selectedAgent: '',
      // ------------------------------
      optionIsClicked: false,
      dataStore: store,
      limitedSda: null
    }
  },
  computed: {
    presetDates () {
      let today = new Date();
      let firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
      let lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

      let firstOfWeek = today.getDate() - today.getDay(); // First day is the day of the month - the day of the week
      let lastOfWeek = firstOfWeek + 6; // last day is the first day + 6
      let firstDayOfWeek = new Date(today.setDate(firstOfWeek))
      let lastDayOfWeek = new Date(today.setDate(lastOfWeek))
      
      let lastOfLastWeek = today.setTime(today.getTime() - 6 * 24 * 60 * 60 * 1000);
      let firstOfLastWeek = today.setTime(today.getTime() - (today.getDay() ? today.getDay() : 7) * 24 * 60 * 60 * 1000);

      const sevenDaysAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)

      return [
        { label: 'Aujourd\'hui', value: [new Date(), new Date()] },
        {
          label: '7 jours glissants',
          value: [sevenDaysAgo, new Date()],
          // slot: 'preset-date-range-button'
        },
        { label: 'Cette semaine', value: [firstDayOfWeek, lastDayOfWeek] },
        {
          label: 'Semaine dernière',
          value: [firstOfLastWeek, lastOfLastWeek],
        },
        { label: 'Ce mois-ci', value: [firstDayOfMonth, lastDayOfMonth] }
      ]
    }
  },
  methods: {
    presetDate (value) {
      this.date = value
    },
    getFilterData () {
      let url = this.$baseUrl+'/api/sda'
      if(this.limitedSda) {
        url += '?limitedSda=' + this.limitedSda
      }
      fetch(url, {
        method: 'GET',
        headers: {
          authorization: 'Basic '+btoa(`${this.dataStore.api.apiUser}:${this.dataStore.api.apiPassword}`)
        }
      })
      .then(res => res.json())
      .then(data => {
        console.log("fetched sda")
          this.sdaList = data
          this.filteredSdaList = this.sdaList
      })

      let url2 = this.$baseUrl+'/api/queues'
      if(this.limitedSda) {
        url2 += '?limitedSda=' + this.limitedSda
      }
      fetch(url2, {
        method: 'GET',
        headers: {
          authorization: 'Basic '+btoa(`${this.dataStore.api.apiUser}:${this.dataStore.api.apiPassword}`)
        }
      })
      .then(res => res.json())
      .then(data => {
        console.log("fetched callgroups")
        this.callGroupList = data
        this.filteredCallGroupList = this.callGroupList
      })
      fetch(this.$baseUrl+'/api/agents', {
        method: 'GET',
        headers: {
          authorization: 'Basic '+btoa(`${this.dataStore.api.apiUser}:${this.dataStore.api.apiPassword}`)
        }
      })
      .then(res => res.json())
      .then(data => {
        console.log("fetched Agents")
        this.agentsList = data
        this.filteredAgentsList = this.agentsList
      })
    },
    formatDate (date) {
      // console.log(date)
      let day0 = date[0]?.getDate();
      let month0 = date[0]?.getMonth() + 1;
      let year0 = date[0]?.getFullYear();

      let day1 = date[1]?.getDate();
      let month1 = date[1]?.getMonth() + 1;
      let year1 = date[1]?.getFullYear();


      //put 0 in front if single-digit
      day0 = day0?.length === 1 ? '0' + day0 : day0;
      month0 = month0?.length === 1 ? '0' + month0 : month0;

      day1 = day1?.length === 1 ? '0' + day1 : day1;
      month1 = month1?.length === 1 ? '0' + month1 : month1;

      return `${day0 ?? 'JJ'}/${month0 ?? 'MM'}/${year0 ?? 'AAAA'} - ${day1 ?? 'JJ'}/${month1 || 'MM'}/${year1 ?? 'AAAA'}`
    },
    selectSda (sdaName) {
      //refresh data based on sda and give value to selectedsda
      this.selectedSda = sdaName
      this.sdaInputFocused = false;
      this.optionIsClicked = true
      this.sendFilters()
    },
    selectCallGroup (callGroupName) {
      //refresh data based on callGroup and give value to selectedCallGroup
      this.selectedCallGroup = callGroupName
      this.callGroupInputFocused = false;
      this.optionIsClicked = true
      this.sendFilters()
    },
    selectAgent (AgentName) {
      //refresh data based on agent and give value to selectedAgent
      this.selectedAgent = AgentName
      this.agentsInputFocused = false;
      this.optionIsClicked = true
      this.sendFilters()
    },
    sendFilters () {
      let filterObject = {
        dates: this.date,
        selectedSda: this.selectedSda,
        selectedCallGroup: this.selectedCallGroup,
        selectedAgent: this.selectedAgent
      }
      console.log('sendFilters')
      this.$emit('updateFilters', filterObject)
    },
    handleBlur () {
      if(!this.optionIsClicked) {
        setTimeout(() => {
          this.sdaInputFocused = false
          this.callGroupInputFocused = false
          this.agentsInputFocused = false
        }, 100)
      }
    }
  },
  mounted () {
    if(JSON.parse(localStorage.getItem('userData')).BoundSda != null) {
      this.limitedSda = JSON.parse(localStorage.getItem('userData')).BoundSda
    }
    this.getFilterData()
  },
  created () {
    this.sendFilters()
  }
}
</script>

<style scoped>
main.sideBar {
  width: 15%;
  min-width: 220px;
  height: 100vh;
  border-right: 1px solid rgba(0, 0, 0, 0.15);
  overflow-y: hidden;
}
.logo-container {
  width: 100%;
  padding: 10px;
}
.filterSelection {
  width: 100%;
  padding: 20px;
}
datalist.sideBar{
  position: absolute;
  background-color: white;
  border: 1px solid rgba(0, 0, 0, 0.15);;
  border-radius: 0 0 5px 5px;
  border-top: none;
  font-family: sans-serif;
  /* width: 100%; */
  width: calc(15% - 40px);
  min-width: 180px;
  padding: 5px;
  max-height: 16rem;
  overflow-y: auto
}

option {
  background-color: white;
  padding: 4px;
  color: black;
  margin-bottom: 1px;
  font-size: 14px;
  cursor: pointer;
}

option:hover, .active{
  background-color: rgba(0, 0, 0, 0.15);
}

input::placeholder{
  font-size: 12px;
}
.navigation-container {
  text-align: left;
  width: 100%;
}

h6 {
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.66;
  font-family: "Public Sans", sans-serif;
  color: rgb(140, 140, 140);
  padding: 8px 28px;
}
.link {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.57;
  font-family: "Public Sans", sans-serif;
  color: rgb(38, 38, 38);
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
  align-items: center;
  width: 100%;
  padding: 12px 28px;
}
.link:hover {
  background-color: rgb(230, 247, 255);
}

.router-link-active {
  border-right: 2px solid rgb(24, 144, 255);
  color: rgb(24, 144, 255);
  background-color: rgb(230, 247, 255);
}
</style>
