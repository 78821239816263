<template>
  <main class="topBar">
    <div class="left">
      <div :class="sidebar ? 'round' : 'square'" class="bg-secondary" @click="toggleSideBar">
        <svg class="rotate color-invert" v-if="sidebar" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 0a256 256 0 1 0 0 512A256 256 0 1 0 256 0zM135 241c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l87 87 87-87c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L273 345c-9.4 9.4-24.6 9.4-33.9 0L135 241z"/></svg>
        <svg v-else class="color-invert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M0 96C0 78.3 14.3 64 32 64l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 128C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32l384 0c17.7 0 32 14.3 32 32s-14.3 32-32 32L32 288c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32L32 448c-17.7 0-32-14.3-32-32s14.3-32 32-32l384 0c17.7 0 32 14.3 32 32z"/></svg>
      </div>
    </div>
    <!-- <div class="mid"></div> -->
    <div class="right">
      <div class="square" @click="redirect('https://www.snsolutions.fr/#contact')"><img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAOCAMAAAAR8Wy4AAAAhFBMVEX////5+fnX19f3/P5by/U1v/IVtfAOsvCS3PheXl41NTUsLCy2trbk9v0jufEAru8ODg4AAACjo6NPx/R51Pc8PDxra2uv5vrv+v5ISEgvLy9WyfRkZGT29vaRkZEUFBQlJSXn5+eW3vh9fX2ampodHR3O7/yurq7Hx8d0dHSOjo5SUlIZjcLnAAAAjElEQVR4ASTKBQ7DQAxE0R/mbFwMM9//gJXVJzDMAFg2ynE9PwixozhJwYEsN8YEFCIPsmfgvYzSxxvH1917esbkJPKBZ+5+S+BrPCqp+bOAJqcV+XRg99UwTswei4gUSSFq4vVkFVW/RbaU3cwcGq5wpBZZM8OphatDOai+vSNZ+A0VaGuhCchqwJkAmfsJ+W/mndsAAAAASUVORK5CYII="/></div>
      <div class="square" @click="redirect('https://www.3cx.fr/forum/help/')"><img src="../assets/3cx.png"/></div>
      <div class="round bg-secondary" @click="$router.push('/dc')">
        <!-- <svg class="color-invert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512l388.6 0c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304l-91.4 0z"/></svg> --><!--UserIcon-->
        <svg class="color-invert" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 224c0 17.7 14.3 32 32 32s32-14.3 32-32l0-224zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"/></svg>
      </div>
    </div>
  </main>
</template>

<script>
// import store from '@/store'
export default {
  name: 'TopBar',
  components: {

  },
  props: {
    sidebar: Boolean
  },
  watch: {

  },
  data () {
    return {

      // dataStore: store
    }
  },
  methods: {
    toggleSideBar () {
      this.$emit('toggleSideBar')
    },
    redirect(url) {
      window.open(url, '_blank')
    }
  },
  mounted () {

  },
  created () {

  }
}
</script>

<style scoped>
.topBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7vh;
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  padding: 1rem;
}
.round {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5vw; /* yes i know it's not the right unit */
  min-height: 30px;
  border-radius: 50%;
  aspect-ratio: 1;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.square {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 1.5vw;
  min-height: 30px;
  aspect-ratio: 1;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.square:hover {
  cursor: pointer;
  background-color: whitesmoke;
}
.round:hover {
  cursor: pointer;
  background-color: whitesmoke;
}
.square img {
  min-height: 20px;
  height: 0.8vw;
  max-width: 1.5vw;
}
.left {
  display: flex;
  justify-content: flex-start;
  gap: 1rem;
}
.right {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.rotate {
  transform: rotate(90deg)
}
.round svg {
  min-height: 20px;
  height: 1vw;
}
.square svg {
  min-height: 20px;
  height: 1vw;
}
</style>
