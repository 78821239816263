<template>
  <main class="d-flex justify-content-center align-items-center vh-100 w-100 bg-light">
    <section class="p-5">
      <h3 class="mb-5 fw-bold">Bienvenue</h3>
      <form>
        <div class="mb-5">
          <label for="email" class="mb-3">Adresse mail</label>
          <input class="form-control" :class="invalid && email == '' ? 'invalid-field' : ''" type="email" name="email" placeholder="Entrez votre adresse-mail" id="email" v-model="email"/>
        </div>
        <div class="mb-5">
          <label for="pwd" class="mb-3">Mot de passe</label>
          <div class="input-group">
            <input class="form-control w-75" :class="invalid && password == '' ? 'invalid-field' : ''" :type="showPwd ? 'text' : 'password'" name="pwd" placeholder="Entrez votre mot de passe" id="pwd" v-model="password"/>
            <button class="form-control w-auto" type="button" @click="showPwd = !showPwd">
              <font-awesome-icon :icon="['far', 'eye']" v-if="showPwd" />
              <font-awesome-icon :icon="['far', 'eye-slash']" v-else />
            </button>
          </div>
        </div>
        <div class="mb-3">
          <input class="form-control bg-primary text-white" type="button" value="Connexion" @click="submitLogin"/>
        </div>
      </form>
      <p :style="{visibility: invalidCredentials ? 'visible' : 'hidden'}" class="text-danger mb-3">L'identifiant ou le mot de passe est incorrect</p>
      <p class="text-center w-100 p-0 m-0 fw-light text-secondary">© SNS SOLUTIONS 2024</p>
    </section>
  </main>
</template>

<script>
import store from '@/store'
export default {
  name: 'LoginPage',
  components: {

  },
  props: {

  },
  watch: {

  },
  data () {
    return {
      showPwd: false,
      email: '',
      password: '',
      invalid: false,
      invalidCredentials: false,
      dataStore: store
    }
  },
  methods: {
    submitLogin () {
      this.invalid = false
      if(this.email == '' || this.password == '') {
        this.invalid = true
        return
      }
      this.sendLoginRequest()
    },
    sendLoginRequest () {
      this.invalidCredentials = false
      const body = this.$genFD({
        LoginUser: this.email,
        PwdUser: this.password
      })
      fetch(this.$baseUrl+'/api/login', {
        method: "post",
        body
      })
      .then(res => {
        if(res.status != 200) {
          this.invalidCredentials = true
          throw new Error()
        }
        else {
          return res.json()
        }
      })
      .then(data => {
        // console.log(data)
        localStorage.setItem('userData', JSON.stringify(data))
        this.dataStore.api.apiUser = data.apiUser
        this.dataStore.api.apiPassword = data.apiPassword
        this.$emit('logged')
        // this.invalidCredentials = true // toggle if creds are wrong
      })
      .catch(err => {
        console.warn(err)
      })
    }
  },
  mounted () {
    // checks if already logged in. Or do this in nav guard ?
    if(localStorage.getItem('userData')) {
      let json = JSON.parse(localStorage.getItem('userData'))
      if(json.LoginUser && json.apiUser && json.apiPassword && json.idUser) {
        this.dataStore.api.apiUser = json.apiUser
        this.dataStore.api.apiPassword = json.apiPassword
        this.$emit('logged')
      }
    }
  }
}
</script>

<style scoped>
main {
  text-align: left;
}
section {
  width: 30%;
  min-width: 300px;
  height: auto;
  background-color: white;
  border-radius: 8px;
  /* box-shadow: 1px 1px 10px 5px rgb(214, 214, 214); */
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
}
.invalid-field {
  border-color: rgba(240, 58, 58, 0.874);
}
</style>
